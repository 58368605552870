.navbar {
  display: grid;
  z-index: 20;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: 120px auto;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  transition: all 0.3s;
  background-color: var(--primary-color);
  border-bottom: 1px solid var(--secondary-color-opacity);
  font-family: "Roboto", sans-serif;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.martop {
  margin-top: 120px !important;
}

.navtop {
  border-top: none;
  background-color: #0e0d27;
  color: white;
  display: grid;
  grid-template-columns: 15% 15%;
  align-items: center;
  justify-content: end;
  padding: 7px;
  gap: 2%;
  font-size: 13px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 4px;
}

.logo img {
  width: 80px;
  height: 40px;
}

.navtop div {
  display: flex;
  align-items: center;
  gap: 3%;
}

.navtop img {
  width: 10%;
}

.navbar ul {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  gap: 40px;
}

.navbar ul li {
  cursor: pointer;
  position: relative;
  color: #0e0d27;
  font-weight: 500;
}

.navbar ul li::before {
  position: absolute;
  content: "";
  width: 0%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #0e0d27;
  transition: all 0.3s ease-in-out;
}

.navbar ul .connect-color a {
  display: inline-block;
  background: linear-gradient(to top , var(--cta-btn), var(--cta-btn-dark));
  padding: 15px 28px;
  border-radius: 8px;
  transition: all 0.3s;
}

.navbar ul .connect-color a:hover {
  box-shadow: 0 0 10px 0 #00000020;
  transform: scale(1.05);
}

.navbar ul li:hover::before {
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.navbar ul li.connect-color::before {
  display: none;
}

.form-btn {
  background-color: var(--cta-btn);
  border-radius: 12px;
  transition: all 0.13s ease-in;
  font-size: 18px;
  padding: 14px 20%;
  color: #0e0d27;
  margin: 12px 0;
  transition: all 0.13s ease-in;
}

.ham-c {
  position: relative;
}

.ham-ul {
  position: absolute;
  z-index: 100;
  background-color: white;
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  color: #010045;
  padding: 15px;
  right: 0;
  border: 2px solid #010045;
  border-radius: 12px;
  width: 320px;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px !important;
  font-size: 16px;
}

.hide {
  display: none !important;
  transition: all 0.2s ease-in-out;
}

.ham-img {
  width: 10%;
  height: auto;
  margin-left: auto;
  margin-right: 10px;
}
