/* Thank You Page Styling */
.thank-you-page {

    min-height: 100vh;
    background-color: #f8f9fa;
    text-align: center;
    border: 5px solid black;
}

/* Container for the Thank You Message */
.thank-you-container {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    margin: 5vh auto;
}

.thank-you-container h1 {
    font-size: 2rem;
    color: #28a745;
    margin-bottom: 10px;
}

.thank-you-container p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
}

.thank-you-container strong {
    color: #333;
}

/* Feedback Form Styling */
.feedback-form {
    margin-top: 20px;
}

.feedback-input {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    resize: none;
    outline: none;
}

.feedback-input:focus {
    border-color: #28a745;
}

/* Submit Button */
.submit-btn {
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.3s;
}

.submit-btn:hover {
    background-color: #218838;
}
